<template>
  <div id="inspire">
    <div class="h-screen" style="background:radial-gradient(#ffffffde, #f8f1f121);">
      <div class="inline-flex pt-7 pl-8 w-full">
        <div class="text-center flex items-center justify-center cursor-pointer">
          <img src="@/assets/images/Zizbey-logo-H-35.svg" />
          <div class="heading-1 font-semibold pl-1" style="color: #1295BA">ZizbeyJobs</div>
        </div>
        <!-- <span><img src="@/assets/images/Zizbey-logo-H-35.svg" class=" h-12" alt="img"></span> -->
      </div>
      <div fluid class="flex  justify-center items-center p-0 px-4 gap-4 pt-20">
        <div class="grow-0 basis-auto xl:w-6/12 lg:w-6/12 hidden lg:flex mb-12 md:mb-0 justify-center">
          <img src="@/assets/images/Img-main.png" alt="front_image"/>
        </div>
        <div v-if="!resetPasswrod"  class="rounded card_design px-4 sm:px-14 py-3 sm:m-0">
          <div class="flex-wrap">
            <div class="text-left">
              <div class="mb-3 p-0 flex justify-center">
                <div class=" text-text1 font-serif" style="font-size:44px;letter-spacing: 1.2px;">Welcome back :)</div>
              </div>
              <div class="my-9 p-0 flex justify-center">
                <div class=" text-text2 font-semibold heading-3" >To Keep connected with us please login with your personal information by Email Address and Password 🔑</div>
              </div>
              <div class="p-0">
                <form @submit.prevent="" autocomplete="false" ref="form">
                  <div class="flex-wrap">
                      <div id="Einputbox" :class="inputFoucus1 ? 'border border-primary' : emailError !== '' ? 'border-2 border-error' :  'border border-gray2 '" class="flex bg-white rounded-md  px-2 py-1.5 items-center overflow-hidden">
                        <div class="relative">
                          <span class="absolute inset-y-0 left-0 flex items-center">
                            <span class="p-1 mt-1 focus:outline-none focus:shadow-outline">
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-gray4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                              </svg>
                            </span> 
                          </span>
                          <input
                          v-model="emailOrphoneNumber"
                          type="text" 
                          class="w-96 py-2 cust_text_field heading-3 text-text2 rounded-t-lg transition ease-in-out pl-12"
                          placeholder="Enter Email"
                          :autocomplete="false"
                          @focus="setBorder1(true)"
                          @blur="setBorder1(false)"
                          @input="setBorder1('input')"
                          @keyup.enter="LoginNow()">
                        </div>
                      </div>
                      <p class="text-error heading-6" v-if="emailError !== ''">{{emailError}}</p>
                      <div id="Einputbox" :class="inputFoucus2 ? 'border border-primary' : errorMessage !== '' ? 'border-2 border-error' : 'border border-gray2 '" class="flex bg-white rounded-md  px-2 py-1.5 items-center mt-4 overflow-hidden">
                        <div class="relative">
                        <span class="absolute inset-y-0 left-0 flex items-center">
                          <span class="p-1 mt-0 focus:outline-none focus:shadow-outline">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                            </svg>
                          </span>
                        </span>
                        <input id="useridPassword" class="w-96 py-2 cust_text_field heading-3 text-text2 pl-12"
                          v-model="password"
                          placeholder="Enter your password"
                          :type="showPassword ? 'text' : 'password'"
                          @click:append="showPassword = !showPassword"
                          @focus="setBorder2(true)"
                          @blur="setBorder2(false)"
                          @input="setBorder2('input')"
                          @keyup.enter="LoginNow()"
                        >
                        <span class="absolute inset-y-0 right-2 flex items-center pl-3">
                          <span class="p-1 pr-2 focus:outline-none focus:shadow-outline">
                            <span v-if="!showPassword" @click="showPassword = !showPassword" class="cursor-pointer"><img height="26px" width="26px" src="@/assets/images/svg/hide-pass.svg" alt="clearInput"></span>
                           <span v-else @click="showPassword = !showPassword" class="cursor-pointer"><img height="16px" width="24px" src="@/assets/images/svg/show-pass.svg" alt="clearInput"></span>
                          </span>
                        </span>
                      </div>
                      </div>
                      <p class="text-error heading-6" v-if="errorMessage !== ''">{{errorMessage}}</p>
                      <p class="text-primary heading-5 text-right font-semibold justify-end" ><span @click="openResetPss()" class="cursor-pointer">Forgot Password?</span></p>
                      <div class="py-1.5 mb-px mt-4">
                        <button
                          class="w-full heading-3 bg-primary2 text-white rounded-full py-2"
                          large
                          @click="LoginNow">Login Now
                        </button>
                      </div>
                  </div>
                </form>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="resetPasswrod" class="popup_overlay px-4">
        <div class="custom_dialog rounded-lg" style="width: 500px;max-height: 95%;">
          <div class="relative bg-white text-center" v-if="!adddNewPassPopup">
          <div class="p-2 mt-4">
            <p class="heading-4">Please enter OTP sent to your Email</p>
            <div class=" my-3 flex justify-center">
              <div class="w-56 heading-4">
                <TextField
                :isOtp="true"
                :textMaxlength="6"
                :inputId="`otpinputvalueid`"
                :inputext="otpValue"
                :placholderText="``"
                :lableText="''"
                :autoFocus="true"
                :inputType="'text'"
                @inputChangeAction="(data) => otpValue = data"  />
              </div>
            </div>
            <div class="flex justify-end">
              <div v-if="countDown > 0" class="pr-4 text-text1">Resend OTP  <span >in {{countDown}} Sec</span></div>
              <div v-else @click="reSendOtp" class=" cursor-pointer text-primary pr-4">Resend OTP</div>
            </div>
            <div class="p-4">
              <div class="py-1.5 mb-px">
                <button :disabled="otpValue.length < 6"
                :class="otpValue.length < 6 ? 'opacity-30 cursor-not-allowed' : ' '"
                  class="w-full heading-5 font-semibold text-white bg-primary2 rounded-full py-2"
                  large
                  @click="resetPasword1">Next
                </button>
              </div>
              <div class=" text-primary text-center mt-2">
                <span class=" cursor-pointer font-bold" @click="gotoSign()">Go to Sign in</span>
              </div>
            </div>
            </div>
          </div>
          <div class="relative bg-white text-center" v-if="adddNewPassPopup">
          <div class="p-2">
            <div class="my-2 text-primary flex justify-center">
              <div class="text-3xl relative">
                <i class="fa-solid fa-key"></i>
              </div>
            </div>
            <div class="pb-3 text-primary"></div>
            <div class=" mb-4">
              <div class="px-5">
                <TextField 
                  :fieldError="setPinValueErr !== ''"
                  @keyPressAction="setPinValueErr = ''"
                  :inputId="`pinsetinpuvalur1`"
                  :inputext="setPinValue"
                  :placholderText="`Password`"
                  :lableText="'Enter Your Password'"
                  :autoFocus="true"
                  :inputType="'text'"
                  @inputChangeAction="(data) => setPinValue = data"  />
                  <p class="text-error text-left"> {{ setPinValueErr }}</p>
                </div>
              </div>
            <div class=" mb-4">
              <div class="px-5">
                <TextField 
                  :fieldError="confirmPinErr !== ''"
                  @keyPressAction="confirmPinErr = ''"
                  :inputId="`pinsetinpuvalur2`"
                  :inputext="confirmPin"
                  :placholderText="`Password`"
                  :lableText="'Re Enter Your Password'"
                  :autoFocus="false"
                  :inputType="'text'"
                  @inputChangeAction="(data) => confirmPin = data"  />
                  <p class="text-error text-left"> {{ confirmPinErr }}</p>
                </div>
              </div>
              <div class="py-2 mb-4 px-5">
                <button :disabled="setPinDisabled"
                :class="setPinDisabled ? 'opacity-30 cursor-not-allowed' : ' '"
                  class="w-full heading-5 font-semibold text-white bg-primary2 rounded-full py-2"
                  large
                  @click="redirectLogin">Save
                </button>
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
    <loader
      v-if="$store.getters.showLoader"
      v-bind:data="$store.getters.loaderMessage"
    ></loader>
    <snakBar
        v-if="$store.getters.showAlert"
        v-bind:status="$store.getters.showAlert"
        v-bind:message="$store.getters.alertMessage"
        v-bind:color="$store.getters.alertColor"
      >
      </snakBar>
  </div>
</template>
<script>
import {getEncryptedPassword} from '@/utils/encrytPassword.js'
import TextField from '@/View/components/textfield.vue'
import { validateEmail} from "@/utils/validation.js";
import * as animationData from '@/assets/animation/pinjump.json'
import {setCookiesWithExpire, removeCookies} from '@/utils/cookies.js'
import Loader from '@/View/components/Loader.vue'
import ADMINAPI from '@/View/Admin/api/Admin.js'
import MyApp from '@/api/App.js'
import snakBar from '@/View/components/SnakBar.vue'
const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export default {
  components: {
    TextField,
    Loader,
    snakBar,
    // TermsCondition,
    // Policy
  },
  data () {
    return {
      passErr: '',
      setPinDisabled: true,
      confirmPinErr: '',
      confirmPin: '',
      setPinValueErr: '',
      setPinValue: '',
      adddNewPassPopup: false,
      countDown: 30,
      otpValue: '',
      resetPasswrod: false,
      emailOrphoneNumber: "",
      emailError: "",
      showCompanyName: false,
      inputFoucus1: false,
      inputFoucus2: false,
      companyLogoUrl: '',
      subdomain: '',
      emailLoginIdentifier: '',
      domainData: {
        companyName: '',
        companyId: 0
      },
      defaultOptions: {loop: false, animationData: animationData},
      animationSpeed: 1,
      alignLayout: 'text-center align-center',
      errorMessage: '',
      password: '',
      showPassword: false,
      errors: {
        password: [v => !!v || 'Please Enter Password'],
        emailError: [v => {
          if (pattern.test(v)) {
            return true || ''
          } else {
            return false || 'Enter valid email'
          }
        }]
      },
      rememberMe: false,
      valid: false,
      lazy: false,
      ConfirmReq: false,
      saToken: '',
      deviceMacAddress: '',
      networkInterfacesDetails: {},
      mobileView: false,
      showTermsAndConditon: false,
      showPolicy: false,
      regToken: '',
    }
  },
  mounted () {
    document.title = 'Login'
  },
  watch: {
    confirmPin: {
      handler () {
        if (this.confirmPin !== '' && this.confirmPin === this.setPinValue) {
            this.setPinDisabled = false
            this.confirmPinErr = ''
          } else {
            this.setPinDisabled = true
            if (this.confirmPin !== '' && this.setPinValue !== '') {
              this.confirmPinErr = 'Password does not match'
            }
        }
      }
    },
    setPinValue: {
      handler () {
        if (this.setPinValue !== '' && this.confirmPin === this.setPinValue) {
            this.setPinDisabled = false
            this.confirmPinErr = ''
        } else {
            this.setPinDisabled = true
            if (this.confirmPin !== '' && this.setPinValue !== '') {
              this.confirmPinErr = 'Password does not match'
            }
        }
      }
    },
    emailOrphoneNumber: {
      handler () {
        console.log('this.emailOrphoneNumber', this.emailOrphoneNumber)
      },
      deep: true
    }
  },
  methods: {
    openResetPss () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Sending OTP.....'})
        ADMINAPI.SendOtpInEmail(
          'sadmin@zizbey.com',
          'login',
          response => {
            this.regToken = response.Data
            this.resetPasswrod = true
            this.countDownTimer()
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
    },
    reSendOtp () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Resending OTP.....'})
        ADMINAPI.ResendOTP(
          this.regToken,
          (response) => {
            this.countDown = 30
            this.countDownTimer()
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        ) 
    },
    gotoSign () {
      clearTimeout(this.countDownTimerId);
      this.resetPasswrod = false
      this.otpValue = ''
      this.countDown = 30;
    },
    countDownTimer() {
      if (this.countDown > 0) {
        this.countDownTimerId = setTimeout(() => { // Store the timer ID
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    redirectLogin() {
      this.$store.dispatch('SetLoader', {status: true, message: 'Saving...'})
      ADMINAPI.UpdateLoginPassword(
        this.regToken,
        this.setPinValue,
        '',
        response => {
          clearTimeout(this.countDownTimerId);
          this.adddNewPassPopup = false;
          this.resetPasswrod = false;
          this.confirmPinErr = '';
          this.currentErr = '';
          this.setPinValue = '';
          this.confirmPin = '';
          this.otpValue = '';
          this.countDown = 30;
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      ) 
    },
    resetPasword1 () {
      this.$store.dispatch("SetLoader", { status: false, message: "Loading...", });
      ADMINAPI.VerifiOTP(
        this.regToken,
        this.otpValue,
        () => {
          this.adddNewPassPopup = true
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: 'OTP verified successfully', color: 'success'})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      ) 
    },
    setBorder1 (val) {
      console.log('TEST')
      if (!val) {
          this.inputFoucus1 = false
      } else {
          this.inputFoucus1 = true
      }
      if (val === 'input') {
          this.inputFoucus1 = true
          this.emailError = ''
      }
    },
    setBorder2 (val) {
        if (!val) {
            this.inputFoucus2 = false
        } else {
            this.inputFoucus2 = true
        }
        if (val === 'input') {
          this.inputFoucus2 = true
          this.errorMessage = ''
      }
    },
    LoginNow () {
      console.log(this.emailOrphoneNumber)
      console.log(this.password)
      if (this.emailOrphoneNumber === "") {
        this.emailError = "Email Address is required";
      }
      if (this.password === "") {
        this.errorMessage = "Password is required";
      }
      if (this.emailError === '') {
        if (validateEmail(this.emailOrphoneNumber) === false) {
          this.emailError = "Please enter valid email";
          return;
        }
      }
      if (this.emailError === '' && this.errorMessage === '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading ...'})
        let data = getEncryptedPassword(this.password)
        console.log('password------->>>>', this.password)
        MyApp.loginToSpecificCompany(
          this.emailOrphoneNumber,
          data,
          response => {
            localStorage.removeItem('resendOtpAttemptsCount')
            localStorage.removeItem('verifyOtpAttemptsCount')
            localStorage.removeItem('emailAddress')
            removeCookies('_SEC_PRLG2DICC')
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            localStorage.removeItem('companyData')
            localStorage.setItem('employeeData1', JSON.stringify(response.Data))
            setCookiesWithExpire('saToken', response.Data.saToken, 30)
            this.$router.push({name: 'dashboard'})
          },
          error => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
      }
    },
  },
  beforeDestroy () {
  },
  computed: {
  }
}
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Saira+Stencil+One&display=swap");
.title {
  font-size: 22px;
  font-weight: 700;
  color: #4169ae;
  margin: 0 0 4px 0;
}
.companyLogo {
  width: 180px;
  height: 100px;
}

.termsAndPolicyTitles {
  color: #139cc4;
  cursor: pointer;
  text-decoration: underline;
}
.logo_color__text {
  color: #139cc4 !important;
}
.saved_email__user {
  display: flex;
  padding: 10px;
  position: relative;
  border: solid lightgray 1px;
  align-items: center;
    border-radius: 4px;
}
.card_design {
  max-width: 500px;
}
</style>
